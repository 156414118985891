import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../component/Layout/Layout";
import Hero from "../component/Hero/Hero";

import loadable from '@loadable/component'

const Ranking = loadable(() => import('../component/Ranking/Ranking'))
const BlogPosts = loadable(() => import('../component/Posts/BlogPosts'))
const FaqPosts = loadable(() => import('../component/Posts/FaqPosts'))
const FaqCollapseQuestion = loadable(() => import('../component/Posts/FaqCollapseQuestion'))
const CompareWidget = loadable(() => import('../component/CompareWidget/CompareWidget'))

import Seo from "../component/Seo/Seo";

import {useStaticQuery, graphql} from "gatsby";
 
const IndexPage = () => {
  const {wpPage} = useStaticQuery(graphql`
    query IndexSeo {
      wpPage(slug: {eq: "strona-glowna"}) {
        seo {
          metaDesc
          title
          focuskw
        }
        opisStrony {
          opis
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title={wpPage.seo.title} description={wpPage.seo.metaDesc} keywords={wpPage.seo.metaDesc} pathname="/" />
      <Hero
        heading="Zacznij oszczędzać na emeryturę z IKZE"
        paragraph={wpPage.opisStrony.opis}
        image={
          <StaticImage
            src="../assets/images/photo/hero-home.jpg"
            height={600}
            width={828}
            quality={90}
            alt="Ranking IKZE"
          />
        }
        showProposal={true}
      />
      <div className="container">
        <CompareWidget />
      </div>
      <Ranking />
      <FaqPosts />
      <FaqCollapseQuestion />
      <BlogPosts />
    </Layout>
  );
};

export default IndexPage;
