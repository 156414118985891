import styled from "styled-components";

export const Paragraph = styled.p`
  color: var(--primary);
  font-size: 20px;
  margin-bottom: 50px;
  margin-top: 50px;
  width: 85%;
  @media screen and (max-width: 991px) {
    width: 100%;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
`;

export const ImageWrapper = styled.div`
  border-radius: 7px;
  box-shadow: 0 10px 40px rgba(1, 74, 173, 0.2);
  img {
    border-radius: 7px;
  }
  @media screen and (max-width: 1199px) {
    width: 100%;
  }
`;

export const ProposalBox = styled.div`
  position: absolute;
  background-color: rgb(242, 246, 251);
  box-shadow: rgba(1, 74, 173, 0.2) 0px 10px 40px;
  z-index: 9;
  text-align: center;
  font-size: 32px;
  color: rgb(1, 74, 173);
  font-weight: bold;
  bottom: 70px;
  left: -30px;
  border-radius: 7px;
  padding: 15px 25px;
  span {
    display: block;
    font-size: 64px;
    line-height: 1.2;
    color: var(--secondary);
  }

  @media screen and (max-width: 1699px){
    bottom: 80px ;
    font-size: 28px;
      span{
          font-size: 56px;
      }
  }
  @media screen and (max-width: 1399px){
    bottom: 100px ;
    font-size: 22px;
      span{
          font-size: 44px;
      }
  }
  @media screen and (max-width: 1199px){
    bottom: 50px ;
  }
  @media screen and (max-width: 850px){
    bottom: 20px ;
    left: 30px;
  }
  @media screen and (max-width: 768px){
    border-radius: 7px;
    font-size: 14px;
      span{
          font-size: 24px;
      }
  }
`;
