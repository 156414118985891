import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import Button from "../Button/Button";
import Heading from "../Heading";
import { ContainerWrapper } from "../shared.style";
import { ImageWrapper, Paragraph, ProposalBox } from "./Hero.style";

export interface IHeroProps {
  /** Heading title */
  heading: string;
  /** Paragraph text */
  paragraph?: string;
  /** Main image */
  image: React.HTMLProps<HTMLImageElement> | React.ReactNode;
  /**  */
  showCta?: boolean;
  showProposal?: boolean;
  showParagraph?: boolean
}

const Hero: React.FC<IHeroProps> = ({heading, paragraph, image, showProposal = false, showCta=true, showParagraph=true}) => {
  return (
    <ContainerWrapper>
      <Container className="py-4 py-lg-5">
        <Row>
          <Col lg={6} className="d-flex justify-content-center align-items-center align-items-lg-start flex-column">
            <Heading title={heading} h={1} />
            {showParagraph && <Paragraph className="mb-10">{paragraph}</Paragraph>}
            {showCta && <Button title="Porównaj oferty IKZE" internalLink="/porownanie-ofert-ikze/" />}
          </Col>
          <Col
            lg={6}
            style={{position: "relative", padding: "15px"}}
            className="d-flex align-items-center py-5 py-lg-0">
            {showProposal && (
              <ProposalBox>
                Twoja dodatkowa
                <br /> emerytura
                <span>1041 PLN*</span>
              </ProposalBox>
            )}
            <ImageWrapper>{image}</ImageWrapper>
          </Col>
        </Row>
      </Container>
    </ContainerWrapper>
  );
};

export default Hero;
