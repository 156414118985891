import React from "react";
import styled from "styled-components";

type HeadingWrapperType = {
  align: string;
  margin: string;
  maxWidth: string
};

const HeadingWrapper = styled.div<HeadingWrapperType>`
  display: flex;
  align-items: ${(props) => (props.align ? props.align : "center")};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  flex-direction: column;
  position: relative;
  max-width: ${ props => props.maxWidth ? props.maxWidth : '100%'};
  div {
    display: flex;
    align-items: center;
    margin: 0;
  }
  span {
    position: relative;
    display: block;
    margin: 30px auto 0 auto;
    width: 200px;
    height: 3px;
    background-color: var(--secondary);
  }
  @media screen and (max-width: 1199px) {
    justify-content: center;
    span {
      width: 100px;
      margin: 10px auto 15px auto;
    }
  }
`;

type CircleType = {
  width: string;
};

const Circle = styled.div<CircleType>`
  position: relative;
  width: ${(props) => (props.width ? props.width : "28px")};
  min-width: ${(props) => (props.width ? props.width : "28px")};
  height: ${(props) => (props.width ? props.width : "28px")};
  min-height: ${(props) => (props.width ? props.width : "28px")};
  background-color: var(--secondary);
  border-radius: 50%;
  margin-right: 16px !important;
  @media screen and (max-width: 1199px) {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    margin-right: 10px !important;
  }
`;

export interface IHeadingProps {
  title: string
  showUnderline?: boolean
  h?: number
  fontSize?: number
  small?: boolean
  circleWidth?: string
  align?: string
  margin?: string
  maxWidth?: string
}

const Heading: React.FC<IHeadingProps> = ({
  title,
  showUnderline = false,
  h = 0,
  fontSize,
  small = false,
  circleWidth = "28px",
  align = "center",
  margin = "0px",
  maxWidth = '100%',
}) => {
  return (
    <HeadingWrapper align={align} margin={margin} maxWidth={maxWidth}>
      <div>
        <Circle width={circleWidth} />
        {h === 0 && <p dangerouslySetInnerHTML={{__html: title}} className={small ? "title--small" : "title"}></p>}
        {h === 1 && <h1 dangerouslySetInnerHTML={{__html: title}} style={{fontSize: fontSize}}></h1>}
        {h === 2 && <h2 dangerouslySetInnerHTML={{__html: title}} style={{fontSize: fontSize}}></h2>}
        {h === 3 && <h3 dangerouslySetInnerHTML={{__html: title}} style={{fontSize: fontSize}}></h3>}
        {h === 4 && <h4 dangerouslySetInnerHTML={{__html: title}} style={{fontSize: fontSize}}></h4>}
      </div>
      {showUnderline && <span></span>}
    </HeadingWrapper>
  );
};

export default Heading;
